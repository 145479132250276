import React, { useState, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// importing all the themes
// import Hometheme from "../themes/home-theme";

// import Tacostheme from "../themes/tacos-theme";

// import TacosthemeForFreshItem from "../themes/tacos-theme-freshItem";
// import Addcarttheme from "../themes/addcart-theme";
// import Securecheckouttheme from "../themes/secure-checkouttheme";
// import TRackordertheme from "../themes/trackorder-theme";
// import Pickedordertheme from "../themes/pickedorder-theme";
// import Arrivedordertheme from "../themes/arrivedorder-theme";
// import useSocket from "../Common/Socket";
// import Dashboardtheme from "../themes/dashboard-theme";
// import Faq from "../Pages/Faq/faq";
// import PrivacyPolicy from "../Pages/termsAndPolicy/privacyPolicy";
import LoginChecker from "../Common/LoginChecker";
import Bloglist from "../Pages/Blog/Bloglist";
import BlogDetail from "../Pages/Blog/BlogDetail";
import DriverSignUp from "../Pages/DriverSignup/Index";

const Hometheme = lazy(() => import("../themes/home-theme"));
const Signuptheme = lazy(() => import("../themes/signup-theme"));
const Tacostheme = lazy(() => import("../themes/tacos-theme"));
const TacosthemeForFreshItem = lazy(() =>
  import("../themes/tacos-theme-freshItem")
);
const Addcarttheme = lazy(() => import("../themes/addcart-theme"));

const Securecheckouttheme = lazy(() =>
  import("../themes/secure-checkouttheme")
);
const TRackordertheme = lazy(() => import("../themes/trackorder-theme"));
const Pickedordertheme = lazy(() => import("../themes/pickedorder-theme"));
const Arrivedordertheme = lazy(() => import("../themes/arrivedorder-theme"));
// const useSocket = lazy(() => import("../Common/Socket"));
const Dashboardtheme = lazy(() => import("../themes/dashboard-theme"));
const Faq = lazy(() => import("../Pages/Faq/faq"));
const PrivacyPolicy = lazy(() =>
  import("../Pages/termsAndPolicy/privacyPolicy")
);

const TermCondition = lazy(() =>
  import("../Pages/termsAndPolicy/termCondition")
);
// const LoginChecker = lazy(() => import("../themes/home-theme"));

const MyRouts = () => {
  // const [socket, connectSocket] = useSocket();
  // const [socketConnect, setsocketConnect] = useState(false);
  // const { success, io } = socket;

  // useEffect(() => {}, [socket]);

  // useEffect(() => {
  //   if (success) {
  //     io.on("order_customer_socket", (socket) => {});
  //   }
  // }, [io]);

  return (
    <div>
      <BrowserRouter>
        <LoginChecker />
        <Routes>
          <Route exact path="/" element={<Hometheme />} />
          <Route exact path="/signup" element={<Signuptheme />} />


          <Route exact path="/tacos/:editId" element={<Tacostheme />} />
          <Route exact path="/restaurants/:editId" element={<Tacostheme />} />
          <Route exact path="/chefs/:editId" element={<Tacostheme />} />
          <Route exact path="/foodtrucks/:editId" element={<Tacostheme />} />
          <Route
            exact
            path="/freshMarket/:editId"
            element={<TacosthemeForFreshItem />}
          />
          <Route exact path="/addcart" element={<Addcarttheme />} />
          <Route
            exact
            path="/secure-checkout"
            element={<Securecheckouttheme />}
          />
          <Route exact path="/track-order/:Id" element={<TRackordertheme />} />
          <Route exact path="/picked-order" element={<Pickedordertheme />} />
          <Route exact path="/arrived-order" element={<Arrivedordertheme />} />
          <Route exact path="/dashboard/:tab?" element={<Dashboardtheme />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/blog" element={<Bloglist />} />
          <Route exact path="/driversignup" element={<DriverSignUp />} />
          <Route exact path="/blog-detail/:editId" element={<BlogDetail />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<TermCondition />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default MyRouts;

export const API_URL = "https://api.imsohungry.com/api/v1";

//////********** TESTING URL *********************/

// export const API_URL = "https://staging-api.imsohungry.com/api/v1";

export const LOGIN_USER = "/web/user/login";
export const SIGNUP_USER = "/web/user/register";
export const RESEND_OTP = "/web/user/resendotp";
export const LOGIN_WITH_OTP = "/web/user/loginwithotp";
export const VERIFY_OTP_DATA = "/web/user/verifyotpdata";


export const VERIFY_OTP = "/web/user/verifyOTP";
export const SIGNUP_DRIVER = "/driver/registerOnCustomerWebsite";

export const FORGOT_PASS = "/web/user/forgotpassword";
export const RESET_PASS = "/web/user/resetpassword";

export const NEARBY_RESTAURANT = "/web/user/restaurant/nearby";
export const RESTAURANT_DETAIL = "/web/user/restaurant/details/";
export const FRESH_MARKET_DETAIL = "/restaurant/getfreshmarketItemByResturent/";
export const COLLECTION_TAB = "/admin/titlelist";
export const MENU_LIST = "/admin/menuitem/";
export const MENU_ITEMS = "/admin/allProducts/";

export const GET_PROFILE = "/web/user/profile";
export const EDIT_PROFILE = "/web/user/updateprofile";
export const UPDATE_PROFILE_IMAGE = "/web/user/upload";

export const ADD_ADDRESS = "/web/user/addaddress";
export const GET_ADDRESS = "/web/user/address";
export const VIEW_ADDRESS = "/web/user/address/view/";
export const EDIT_ADDRESS = "/web/user/updateaddress";
export const DEL_ADDRESS = "/web/user/removeaddress";
export const ADD_PAYMENT = "/payment/addpos";
export const PAYMENT_LIST = "/payment/poslist";
export const DELETE_CARD = "/payment/deletepos";

export const ADD_MONEY = "/payment/addmoney";

export const ADD_CART = "/web/user/addtocart";
export const UPDATE_ADD_CART = "/web/user/updatetocart";
export const ADD_CART_QUANTITY = "/web/user/updatecarts";

export const USER_CART = "/web/user/usercarts";
export const USER_CART_REMOVE = "/web/user/usercartRemove";

export const ADD_ORDER = "/web/user/addorder";

export const PROMOLIST = "/promo/promocodeslist";
export const DRIVERLIST = "/web/user/drivers/listing";
export const UBER_DRIVERLIST = "/web/user/check/delivery";

export const COMPARE_LIST = "/web/user/getallByfilter";

export const FAQ_LIST = "/admin/getAllFaqs";

export const ORDERS_LIST = "/web/user/everything";
export const ORDER_DETAIL = "/web/user/orderdetail/";

export const FEEDBACK = "/web/user/feedbackbycustomer";
export const CONTACTUS = "/web/user/addcontactuser";
export const GUEST_REGISTER = "/web/user/guest-register";

export const BANNER_LIST = "/user/gifpromobanner";

export const PRIVACY_POLICY = "/admin/privacypolicy";

export const TERM_CONDITION = "/admin/termsandconditions";


export const BLOG_LIST = "/admin/blog/getWithFilter";
export const BLOG_DETAIL = "/admin/blog/getBySlug/";

// export const CHANGE_PASSWORD = "user/change-password";

// export const GET_PROMOTION = "user/bonus";
// export const GET_PROMOTION_BY_ID = "user/bonusById/";
// export const GET_GAME_BY_ID = "game/gameById/";

export const VEHICLE_TYPE = "/main/adminCarTypes/getCarType";

export const STRIPE_PUBLIC_KEY = "pk_test_LLAdXVApiHYl2QUmtHy2HiHT";

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character (@,-,~,_), numeric value.";

import { toast } from "react-toastify";

export const catchAsync = (fn, setLoader, callBack) => {
  return (...arg) =>
    fn(...arg).catch((error) => {
      toast.dismiss();
      toast.error("something went wrong");
      setLoader && setLoader(false);
      callBack && callBack();
      console.log(error, "error");
    });
};

export function getCityNameFromTheAddressComponent(addressComponents) {
  for (const component of addressComponents) {
    if (component?.types?.includes("locality")) {
      return component?.long_name;
    }
  }
  return null;
}

export const checkResponse = ({
  res,
  setData,
  setTotal,
  showSuccess,
  dataToSet,
  setLoader,
  totalCount,
}) => {
  if (res?.data?.status === "success") {
    setData && setData(dataToSet || res?.data?.data);
    setTotal && setTotal(totalCount || res?.data?.totalcount);
    if (showSuccess) {
      toast.dismiss();
      toast.success(res?.data?.message);
    }
    setLoader && setLoader(false);
    return true;
  } else {
    toast.dismiss();
    toast.error(res?.data?.message);
    setLoader && setLoader(false);
    return false;
  }
};

export const removeUnderScore = (str) => {
  if (!str) {
    return "";
  }

  return str.replace(/_/g, " ");
};

export const removeUnderscoresAndCapitalize = (str) => {
  const stringWithoutUnderscores = removeUnderScore(str);
  return (
    stringWithoutUnderscores.charAt(0).toUpperCase() +
    stringWithoutUnderscores.slice(1)
  );
};

export const removeFlagFromCountryCode = (value) => {
  if (!value) return value;
  return `+${parseInt(value.match(/\d+/)[0], 10)}`;
};
